import React, { useEffect } from 'react'

const AddThis = (props) => {
    useEffect(() => {
        const script = document.createElement('script')
        script.src = '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f7b7b0bcd6c1550'
        script.async = true
        document.body.appendChild(script)
        return () => document.body.removeChild(script)

        /*if (window.addthis) {
            window.addthis.init()
            window.addthis.update('share', 'url', props.url)
        }*/
    }, [props.url])

    return ( <></> )
}

export default AddThis
