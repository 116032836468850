/* eslint-disable react/jsx-indent */
import React, { useState } from 'react';
import { graphql, Link } from 'gatsby';
import { sampleSize } from 'lodash';

import Layout from '../components/layout/Layout';
import AddThis from '../components/global/AddThis';
import ImageFromField from '../components/global/ImageFromField';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';
import SEO from '../components/SEO';
import useAuth from '../util/hooks/useAuth';
import ShareIcons from '../components/global/ShareIcons';

const StoryPage = ({ data, pageContext }) => {
  useAuth();
  const [device, setDevice] = useState('mobile');
  const { title, bodyContent, relationships, next, previous } = pageContext;
  const { featuredOn } = data;
  const subMenu = { title: 'about', extra: true };

  const otherNews = sampleSize(data.allNodeStory.edges, 3);

  const url = typeof window !== 'undefined' ? window.location.href : '';

  return (
    <Layout
      device={device}
      setDevice={setDevice}
      logo={data.logo}
      menu={data.allMenuMenuLink}
      footerMenu={data.footer_menu}
      subMenu={subMenu}
      locations={data.locations.edges}
    >
      <SEO title={title} image={relationships?.field_story_image?.uri?.url} />
      <main className="news-story">
        <section className="grid-section grid-content-container t-left story-news-page">
          <div className="breadcrumbs">
            <Link to="/about-loch-gallery">About</Link>
            <span>&gt;</span>
            <Link to="/news">News</Link>
            <span>&gt;</span>
            {title}
          </div>
          <div className="heading-content">
            <div className="title-content">
              {relationships?.field_story_image?.uri ? (
                <ImageFromField img={relationships.field_story_image} />
              ) : null}
              <div className="t-heading-container">
                <h1 className="t-large t-heading t-uppercase news-title">
                  {title}
                </h1>
              </div>
            </div>
          </div>
          <div className="spacer"></div>
          <article
            className="t-content-container body-content t-left"
            dangerouslySetInnerHTML={{ __html: bodyContent }}
          ></article>
          <div className="spacer"></div>
          <div className="t-left">
            <button
              className="button"
              onClick={() => {
                window.print();
              }}
            >
              Print
            </button>
          </div>
          <div className="spacer"></div>
          <div className="next-previous">
            {next ? (
              <Link className="previous" to={next.path.alias}>
                <FaArrowLeft /> previous news
              </Link>
            ) : null}
            {previous ? (
              <Link className="next" to={previous.path.alias}>
                next news <FaArrowRight />
              </Link>
            ) : null}
          </div>
          <div className="spacer"></div>
        </section>
        <section className="grid-section t-center enjoy-this">
          <div className="grid-content-container">
            <div className="t-heading-container">
              <h2 className="t-heading">Did you enjoy this?</h2>
              <p>Share this with others...</p>
              <ShareIcons url={url} title={title} />
            </div>
          </div>
        </section>
        <section className="grid-section t-center other-news">
          <h2 className="t-heading t-uppercase background-line">
            <span>Other News Articles</span>
          </h2>
          <div className="grid-content-container">
            <div className="t-heading-container">
              {otherNews.length ? (
                <div className="grid-3">
                  {otherNews.map(({ node: story }, i) => (
                    <article className="article grid-col" key={i}>
                      <Link to={story.path.alias}>
                        {story.relationships.field_story_image?.uri ? (
                          <div className="img-wrapper">
                            <ImageFromField
                              img={story.relationships.field_story_image}
                              imageSize="gatsby_news_thumbnail"
                            />
                          </div>
                        ) : null}
                        <h3 className="t-medium news-title">{story.title}</h3>
                      </Link>
                    </article>
                  ))}
                </div>
              ) : null}
            </div>
          </div>
        </section>
      </main>
      <AddThis />
    </Layout>
  );
};

export default StoryPage;

export const StoryQuery = graphql`
  query StoryQuery($title: String = "") {
    allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "gatsby-menu" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          drupal_parent_menu_item
          bundle
          drupal_id
          menu_name
        }
      }
    }
    footer_menu: allMenuMenuLink(
      sort: { fields: [weight], order: ASC }
      filter: { menu_name: { eq: "footer" } }
    ) {
      edges {
        node {
          enabled
          title
          expanded
          external
          langcode
          weight
          link {
            uri
          }
          bundle
          drupal_id
          menu_name
        }
      }
    }
    locations: allNodeLocation {
      edges {
        node {
          field_address {
            address_line1
            address_line2
            country_code
            locality
            postal_code
          }
          field_address_description
          field_address_short
          field_location_email
          field_location_fax_number
          field_location_phone_number
          title
          field_office_hours_description
        }
      }
    }
    allNodeStory(
      limit: 12, 
      filter: { 
        relationships: {
          field_story_image: {
            status: { eq: true }
          }
        }
        status: { eq: true }
        title: { ne: $title } 
      }
    ) {
      edges {
        node {
          title
          path {
            alias
          }
          relationships {
            field_story_image {
              image_style_uri {
                gatsby_news_thumbnail
              }
              uri {
                url
              }
            }
          }
        }
      }
    }
  }
`;
